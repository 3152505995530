.App {
  background-color: #FAFAFA;
}

.App-header {
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 0px #CDCFD6;
  border-bottom: 1px solid #EFEFEF;
  padding: 5px 20px 5px 20px;
  justify-content: space-between;
  background-color: #ffffff;
}

.App-link {
  color: #09D3AC;
}

input {
  color: #43464B;
  margin-top: 0px;
  border-radius: 4px;
  border: 1px solid #B1B1B1;
  background-color: #FAFAFA;
  padding: 5px 10px 5px 10px;
}

.Input-Error {
  border-color: #FF5F5F;
}

.Input-Error-Message {
  color: #FF5F5F;
  font-size: 10px;
  padding: 5px 0px 5px 0px;
}

*:focus {
  outline: none;
}

input:focus {
  border-color: #3994AE;
}

::placeholder {
  color: #CBCBCB;
  opacity: 0.7;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #CBCBCB;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: #CBCBCB;
}

.Simple-Card-View {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
}

.flexcontainer {
  display: flex;
  flex-flow: row wrap;
}

.table-row-selection:hover {
  cursor: pointer;
  background-color: #F0F0F0;
}

.Card-View {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 15px rgba(65, 61, 74, 0.1);
}

.Card-View-Clickable {
  cursor: pointer;
}

.Card-View-Clickable:hover {
  box-shadow: 0px 3px 15px #343B4029 !important;
}

.Circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
}

.Button {
  height: 35px;
  display: flex;
  color: #43464B;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid #767171;
  background-color: #FFFFFF;
}

.Button-Disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.Button:hover {
  background-color: #EFEFEF;
}

.Button:active {
  bottom: -4px;
  box-shadow: 0px 4px 8px rgba(11, 14, 255, 0.3);
}

.Primary-Button {
  color: white;
  background-color: #3994AE;
}

.Primary-Button:hover {
  box-shadow: black;
  border-color: black;
  background-color: #3994AE;
}

.Secondary-Button {
  color: #3994AE;
  background-color: white;
}

.Secondary-Button:hover {
  color: white;
  background-color: #3994AE;
}

.Error-Secondary-Button {
  color: #FF5F5F;
  background-color: white;
  border-color: #FF3131;
}

.Error-Secondary-Button:hover {
  color: #FFFFFF;
  background-color: #FF5F5F;
  border-color: #FF3131;
}

.Error-Button {
  color: #FFFFFF;
  box-shadow: #FF5F5F4D;
  border-color: #FF5F5F;
  background-color: #FF5F5F;
}

.Error-Button:hover {
  box-shadow: #FF5F5F80;
  border-color: #FF3131;
  background-color: #FF3131;
}

.Dark-Button {
  color: #FFFFFF;
  box-shadow: #9B9B9B;
  border-color: #9B9B9B;
  background-color: #9B9B9B;
}

.Dark-Button:hover {
  box-shadow: #7C7C7C;
  border-color: #7C7C7C;
  background-color: #7C7C7C;
}

.Success-Button {
  color: #FFFFFF;
  box-shadow: #81DE794D;
  border-color: #81DE79;
  background-color: #81DE79;
}

.Success-Button:hover {
  box-shadow: #5ABC5280;
  border-color: #5ABC52;
  background-color: #5ABC52;
}

.Accept-Selected {
  color: #FFFFFF;
  background-color: #72C06C;
  border-color: #72C06C;
}

.Accept-Selected:hover {
  color: #FFFFFF;
  background-color: #72C06C;
  border-color: #72C06C;
}

.Reject-Selected {
  color: #FFFFFF;
  background-color: #E85353;
  border-color: #E85353;
}

.Reject-Selected:hover {
  color: #FFFFFF;
  background-color: #E85353;
  border-color: #E85353;
}

.Accept-Button {
  color: #72C06C;
  border-color: #72C06C;
}

.Accept-Button:hover {
  color: #FFFFFF;
  background-color: #72C06C;
}

.Reject-Button {
  color: #E85353;
  border-color: #E85353;
}

.Reject-Button:hover {
  color: #FFFFFF;
  background-color: #E85353;
}

.Move-Button {
  color: #FAFAFA;
}

.Move-Button:hover {
  color: black;
  cursor: pointer;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}

.pagination > li {
  list-style: none;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #4B4B4B;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #40A7FF;
  border-radius: 4px;
}

.pagination > li > a:hover {
  background-color:  #52606C;
  color: white;
  border-radius: 4px;
}

textarea {
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  border-style: solid;
  overflow: auto;
}

textarea:focus {
  outline: none !important;
  border-color: #3994AE;
}

textarea:focus {
  outline: none !important;
  border-color: #3994AE;
}

.modalButtonView {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.language-mermaid {
  color: black;
  text-align: center;
}

strong {
  font-family: 'Nunito Sans', sans-serif;
}

.fixed_header{
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  max-height: 300px;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header th, .fixed_header td {
  width: 300px;
}

.watermark {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3994AE;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3994AE;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-text {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-text:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-text {
  background-color: #3994AE;
}

input:focus + .slider-text {
  box-shadow: 0 0 1px #3994AE;
}

input:checked + .slider-text:before {
  -webkit-transform: translateX(90px);
  -ms-transform: translateX(90px);
  transform: translateX(90px);
}

.brief-qns-on {
  display: none;
}

.brief-qns-on, .brief-qns-off {
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+ .slider-text .brief-qns-on {
  display: block;
}

input:checked + .slider-text .brief-qns-off {
  display: none;
}

.slider-text.round {
  border-radius: 40px;
}

.slider-text.round:before {
  border-radius: 50%;
}

.brief-basic-details ul {
  padding-left: 20px;
}

.brief-basic-details ol {
  padding-left: 25px;
}

.brief-basic-details .katex {
  font-size: 18px;
}

.brief-basic-details h1, .brief-basic-details h2, .brief-basic-details h3, .brief-basic-details h4, .brief-basic-details h5, .brief-basic-details h6 {
  color: #006199;
}

.brief-basic-details h1 {
  font-size: 22px;
}

.brief-basic-details h2 {
  font-size: 20px;
}

.brief-basic-details h3 {
  font-size: 18px;
}

.brief-basic-details h4 {
  font-size: 16px;
}

.brief-basic-details h5 {
  font-size: 14px;
}

.brief-basic-details h6 {
  font-size: 12px;
}

.Graph-Card-View {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 1px 2px 6px #8B9DAF26;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
}

.Graph-Card-View:hover {
  box-shadow: 0px 3px 15px #343B4029 !important;
}

.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #3994AE;
  cursor: pointer;
}

.New-Input {
  color: #43464B;
  margin-top: 0px;
  border-radius: 0px;
  border: 0px;
  border-bottom: 2px solid #DDD;
  background-color: inherit;
  padding: 5px 10px 5px 10px;
}

.New-Input:focus {
  border: 0px;
  border-bottom: 2px solid #673AB7 !important;
}

.New-Input-Hover {
  border: 0px;
}

.New-Input-Hover:hover {
  border-bottom: 2px solid #ddd;
}

@media print {
  .watermark {
    position: fixed;
    display: block;
  }

  .hide-button {
   display: none;
  }

  .bg-text {
    color: #ddd;
    font-size: 120px;
    font-family: arial;
    font-weight: bold;
    transform: rotate(310deg);
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(310deg);
    opacity: 0.2;
    z-index: 1;
  }
}
